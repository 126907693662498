@import './../../../sass/reuse';

.mainContainer {
  display: flex;
  flex-direction: column;
  color: #222;
  @include tablet {
    flex-direction: row;
  }
}
