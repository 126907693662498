@import './../sass/reuse';

.common {
  color: black;
}

.homePage {
  color: blue;
  .searchImage {
    width: 30px;
    height: 30px;
  }
}

form.common:first-of-type {
  display: flex;

  input[name='search'] {
    padding: 0 24px 0 22px;
    height: 48px;
    font-size: 17px;
    border: none;
    margin-right: 8px;
    background: var(--advanced-search-background-color);
    color: var(--search-color);
    letter-spacing: initial;
    &::placeholder {
      color: var(--search-color);
      font-size: 17px;
      text-transform: initial;
      letter-spacing: initial;
    }

    &:active,
    &:focus {
      box-shadow: none;
      & + span {
        color: #dbdbdb;
      }
    }

    & + span {
      transform: translate(0px, 0px);
      position: initial;
      background: #e728ea;
      border-radius: 8px;
      width: 48px;
      height: 48px;
      display: flex;
    }
  }
  & > div {
    right: 55px;
  }
}

.advancedSearch {
  margin-top: 5px;
  display: none;
  position: relative;

  &:not(.isStandalone) {
    @include desktop {
      display: block;
      flex: 1;
      max-width: 442px;
      margin-left: auto;
    }
  }

  input {
    background-color: var(--search-input-background-color);
    font-weight: 400;
    letter-spacing: 2.6px;
    // Safari fix
    line-height: 2.5em;
    &::placeholder {
      opacity: 0.33;
      font-size: 10px;
      color: #000;
    }

    border: 1px solid var(--search-border-color);
    &:active,
    &:focus,
    &:hover {
      border-color: var(--search-border-color);
      box-shadow: none;
    }
  }

  &.isStandalone {
    display: block;

    input {
      font-size: 37px;
      font-weight: 500;
      letter-spacing: 7.4px;
      &::placeholder {
        text-align: left;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 3px;
      }

      + span {
        top: 50% !important;
        left: 20px !important;
        transform: translateY(-50%);
      }
    }
  }
}

.searchDropdown {
  position: absolute;
  background-color: var(--header-dropdown-menu-background-color);
  top: calc(100% - #{$radius});
  left: 0;
  right: 0;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  z-index: 2;
  padding-top: $radius;
  header {
    background-color: rgba(#170707, 0.06);
    display: flex;
    align-items: center;
    padding: 5px 16px;
    h2 {
      color: #535353;
      font-size: $size-7;
      font-weight: $weight-bold;
      letter-spacing: 2px;
    }
  }

  a {
    color: #535353;
    &:hover {
      color: $purple;
      h3 {
        color: $purple;
      }
    }
  }

  ul {
    padding: $gap / 2;
    position: relative;
  }

  li {
    padding-top: $gap / 3;
    padding-bottom: $gap / 3;
  }
}

.searchThing {
  font-size: $size-7;
  display: flex;
  align-items: center;
  .searchImage {
    position: relative;
  }
  img {
    object-fit: contain;
  }

  h3 {
    text-transform: none;
    letter-spacing: 0.12px;
    font-weight: 400;
    font-size: $size-7;
    margin-left: $gap / 2;
  }

  strong {
    display: block;
  }
}

.artists,
.posts {
  .searchThing {
    .imgContainer {
      position: relative;
    }
    img {
      border-radius: 50%;
      object-fit: cover;
    }
    h3 {
      font-weight: 500;
    }
  }
}

.posts {
  .searchThing {
    align-items: flex-start;
  }
  strong {
    margin-top: 8px;
  }
}

.albums,
.events,
.courses {
  .searchThing {
    font-size: $size-7;
    display: block;
    text-align: center;
    h3 {
      font-size: $size-7;
      margin-left: 0;
    }
  }
}

.events {
  strong {
    margin-bottom: 4px;
  }
}

.themes,
.genres {
  button {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  ul {
    padding: $gap / 2;
  }
}

.viewAll {
  margin-left: auto;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.common .searchDropdown {
  padding-top: 0;
  section {
    background-color: var(--search-dropdown-background-color);
    box-shadow: 0 24px 32px var(--seach-dropdown-shadow-color);

    header {
      background-color: var(--search-dropdown-header-background-color);

      h2 {
        color: $white;
        font-size: 10px;
        font-weight: $weight-bold;
        font-style: normal;
        letter-spacing: 2px;
      }
    }
    ul {
      padding: $gap * 2;
    }
  }
  .searchThing {
    flex-direction: column;
    .searchImage {
      position: relative;
    }
    img {
      box-shadow: 0 14px 17px var(--song-box-shadow-color);
    }
    strong {
      color: var(--body-color);
    }
    &:hover {
      .searchImage {
        position: relative;
        @include zoomInOut(false);
        &::after,
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          font-size: 60px;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:after {
          box-sizing: border-box;
          background: #000;
          opacity: 0.5;
          content: '';
          z-index: 1;
        }
        &:before {
          content: '+';
          z-index: 2;
        }
      }
    }
    img {
      margin-right: 0 !important;
    }
    h3 {
      color: $white;
      margin-top: 10px;
    }
  }
}
