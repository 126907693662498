@import './../sass/reuse';

.featuredHomepage {
  background-color: #262626;
  border-radius: 10px;
  overflow: hidden;

  height: calc(100vh - #{$header-height});

  @include tablet {
    height: 442px;
  }
  @include mobile {
    height: 180px;
  }
}

.recentlyAdded {
  & > div {
    background-color: var(--recently-added-block);
  }
  @include fullhd {
    header + div {
      padding: 16px;
    }
  }
}

.topArtists {
  @include fullhd {
    header + div {
      padding: 38px;
    }
  }
}

.errorPage {
  max-width: 100% !important;
  position: relative !important;
  background-color: $black;
  margin-bottom: 0 !important;
  height: 230px;
  padding-bottom: 22px;
  max-height: calc(100vh - 150px);

  @include tablet {
    height: 400px;
    padding-bottom: 30px;
  }
  @include desktop {
    height: 700px;
    padding-bottom: 60px;
  }
  @include fullhd {
    padding-bottom: 90px;
    height: 1015px;
  }
}

.errorBgContainer {
  position: relative;
  height: 100%;
}

.errorBg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.errorPageContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.errorImgs {
  flex: 0 0 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 140px;

  @include desktop {
    padding-left: 100px;
  }
  @include fullhd {
    padding-left: 200px;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  h3 {
    color: $white !important;
    font-weight: 700 !important;
    margin: 15px 0 0 !important;
    font-size: 13px !important;

    @include tablet {
      font-size: 20px !important;
    }
    @include desktop {
      font-size: 40px !important;
      line-height: 45px !important;
      margin: 40px 0 0 !important;
    }
    @include fullhd {
      font-size: 62px !important;
      line-height: 76px !important;
    }
  }
}

.notesContainer {
  display: none;
  position: absolute;
  right: calc(50% - 400px);
  bottom: 400px;
  @include desktop {
    display: flex;
    right: calc(50% - 320px);
    bottom: 300px;
    width: 220px;
  }
  @include fullhd {
    right: calc(50% - 420px);
    bottom: 400px;
    width: 320px;
  }
}

.img404Container {
  display: flex;
  width: 140px;
  @include tablet {
    width: 250px;
  }
  @include desktop {
    width: 400px;
  }
}

.errorText {
  flex: 0 0 50%;
  @include fullhd {
    padding-left: 50px;
  }
  h1 {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: $white !important;
    margin: 0 !important;

    @include tablet {
      font-size: 70px !important;
    }
    @include desktop {
      font-size: 90px !important;
    }
    @include fullhd {
      font-size: 110px !important;
    }
  }
  h2 {
    font-size: 13px !important;
    line-height: normal !important;
    font-weight: 400 !important;
    color: $white !important;
    max-width: 300px;
    margin: 0 !important;
    @include tablet {
      font-size: 30px !important;
      line-height: 45px !important;
      max-width: 600px;
    }
    @include desktop {
      font-size: 40px !important;
      line-height: 55px !important;
    }
    @include fullhd {
      font-size: 50px !important;
      line-height: 65px !important;
    }
  }
  p {
    font-size: 11px;
    font-weight: 500;
    color: $white;
    line-height: normal;
    margin: 0 !important;
    @include tablet {
      font-size: 20px;
      line-height: 30px;
    }
    @include desktop {
      font-size: 23px;
      line-height: 50px;
    }
    @include fullhd {
      font-size: 26px;
      line-height: 76px;
    }
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #212121;
    border-radius: 34px;
    background-color: #ffffff;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    width: 90px;
    height: 25px;
    margin-top: 10px;
    font-size: 11px;
    @include tablet {
      width: 140px;
      height: 40px;
      margin-top: 30px;
      font-size: 15px;
      letter-spacing: 4.25px;
    }

    @include desktop {
      margin-top: 40px;
      width: 186px;
      height: 51px;
      font-size: 17px;
    }
  }
}
